<template>
 <div class="text-center">
 

    <v-overlay :value="true">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
 
</template>

<script>
  export default {
    name: 'Home',
    data: () => ({
      overlay: false,
    }),
    mounted(){
      this.setOverlay();
    },

    watch: {
      overlay (val) {
        val && setTimeout(() => {
          this.overlay = false
        }, 3000)
      },
    },
    methods: {
      setOverlay(){
         this.overlay = true
      }
    }
    
  }
</script>
