import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/app/:id/:fs',
    name: 'auth',
    component: () => import('../views/auth.vue')
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('../views/Personal.vue')
  },
  {
    path: '/parents',
    name: 'Parents',
    
    component: () => import('../views/Parents.vue')
  },
  {
    path: '/siblings',
    name: 'Siblings',
    component: () => import('../views/Bradsis.vue')
  },
  {
    path: '/educ',
    name: 'Educ',
    component: () => import('../views/Educ.vue')
  },
  {
    path: '/health',
    name: 'Health',
    component: () => import('../views/Health.vue')
  },
  {
    path: '/addprof',
    name: 'Addprof',
    component: () => import('../views/Addprof.vue')
  },

  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
